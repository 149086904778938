<template>
  <v-row class="ma-0 pa-0 fill-height mt-16" align="center">
    <v-col cols="12" lg="5" class="my-16 mx-auto">
      <v-card tile :min-height="$vuetify.breakpoint.height * 0.4" width="100%" class="pa-8">
        <v-card-title class="display-2 main-title text-uppercase text-center">
          <span class="mx-auto mt-10">Einloggen</span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="login" v-slot="{ passes }">
            <v-form @submit.prevent="passes(submit)">
              <validation-provider name="Einloggen" rules="required|email" v-slot="{ errors }">
                <v-text-field label="Einloggen"
                              v-model="form.username"
                              :error-messages="errors"
                              placeholder="Geben Sie Ihr Login (die E-Mail Adresse) ein"
                              type="email" class="mt-5 mb-5"></v-text-field>
              </validation-provider>
              <validation-provider name="Passwort" rules="required" v-slot="{ errors }">
                <v-text-field
                    label="Passwort"
                    v-model="form.password"
                    :error-messages="errors"
                    placeholder="geben Sie Ihr Passwort"
                    type="password" class="mb-5"></v-text-field>
              </validation-provider>
              <div class="ma-0 pa-0 d-flex justify-center">
                <v-btn type="submit" width="100%" color="secondary">
                  <small v-if="$vuetify.breakpoint.mobile" class="font-weight-bold">Anmelden</small>
                  <span v-else class="title font-weight-bold">Anmelden</span>
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Login",
  data () {
    return {
      form: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    submit () {
      this.$http.post('/login', this.form)
    }
  }
}
</script>

<style scoped lang="scss">
.main-title {
  text-align: center;
  font-weight: 900;
  color: transparent;
  -webkit-text-stroke: 2px #000;
  line-height: 135%;
  letter-spacing: 0.085em !important;
  @media (max-width: 576px) {
    font-size: 2.5rem !important;
  }
}
</style>
